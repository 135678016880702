import * as React from "react";
import {Scatter} from 'react-chartjs-2';
import Button from "@material-ui/core/Button";
import {Typography} from "@material-ui/core";
import Slider, {createSliderWithTooltip, Range} from 'rc-slider';
import 'rc-slider/assets/index.css';
import './App.css';

const MainSlider = createSliderWithTooltip(Slider);

class Graph extends React.Component {
    constructor(props) {
        super(props);
        this.vals = {'a': 1, 'b': 2, 'c':0};
        this.state = {points: this.calculateData(this.vals['a'], this.vals['b'], this.vals['c'])};
        this.data = {
            labels: ['Exponential Graph'],
            datasets: [
                {
                    label: 'Exponential Function',
                    // lineTension: 0,
                    fill: false,
                    showLine: true,
                    borderColor: "#808080",
                    pointBorderColor: 'rgba(75,192,192,1)',
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                    pointHoverBorderColor: 'rgba(220,220,220,1)',
                    pointHoverBorderWidth: 2,
                    pointHitRadius: 10,
                }]
        };
        this.options = {
            scales: {
                yAxes: [{
                    display: true,
                    ticks: {
                        min: 0,
                        max: 300,
                        stepSize: 30,
                    }
                }]
            }
        };
        this.changeData = this.changeData.bind(this);
    }

    changeData() {
        let latest = this.calculateData(this.vals['a'], this.vals['b'], this.vals['c']);
        this.setState({points: latest});
    }

    calculateData(a, b, c) {
        let data = [];
        for (let i = -2; i < 11; i++) {
            data.push({x: i, y: ((a * Math.pow(b, i)) + c)})
        }
        return data;
    }

    render() {
        this.data.datasets[0].data = this.state.points;
        const handleChange = (val, letter) => {
            this.vals[letter] = val;
            this.changeData();
        };
        return (
            <div>
                <Scatter data={this.data} height={110} options={this.options}/>
                <Typography variant='h5' paragraph>
                    Exponential Equation: <code>y=a(b^x)+c</code>
                </Typography>
                <Typography variant='h6'>
                    <div className='sliders'>
                        <div className='label'>A:</div>
                        <div className='slider'>
                            <MainSlider defaultValue={this.vals['a']} min={0} max={10} step={.1} onChange={(val)=>handleChange(val,'a')}/>
                        </div>
                    </div>
                    <div className='sliders'>
                        <div className='label'>B:</div>
                        <div className='slider'>
                            <MainSlider defaultValue={this.vals['b']} min={0.1} max={10} step={.1}  onChange={(val)=>handleChange(val,'b')}/>
                        </div>
                    </div>
                    <div className='sliders'>
                        <div className='label'>C:</div>
                        <div className='slider'>
                            <MainSlider defaultValue={this.vals['c']} min={0} max={70} step={1}  onChange={(val)=>handleChange(val,'c')}/>
                        </div>
                    </div>
                </Typography>
                <br/>
            </div>
        )
    }

}

export default Graph;