import React from 'react';
import './App.css';

import {Link, Route, Switch} from 'react-router-dom'

import CssBaseline from "@material-ui/core/CssBaseline";
import {createMuiTheme} from '@material-ui/core/styles';
import {makeStyles} from '@material-ui/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Divider from '@material-ui/core/Divider';
import Box from "@material-ui/core/Box";


import NextIcon from '@material-ui/icons/NavigateNext'
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import Graph from './Graph.js';

function importAll(r) {
    let images = {};
    r.keys().map((item, index) => {
        images[item.replace('./', '')] = r(item);
    });
    return images;
}

const images = importAll(require.context('./images', false, /\.(png|jpe?g|svg|gif)$/));


const theme = createMuiTheme({});

const AppBarStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
});

function NavBar() {
    const classes = AppBarStyles();
    return (

        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <AppBar position="fixed">
                <Toolbar>
                    <Typography variant="h6" color="inherit" className={classes.grow}>
                        Exponential Functions
                    </Typography>
                </Toolbar>
            </AppBar>
        </ThemeProvider>
    );
}


let MenuStyle = makeStyles({
    root: {
        margin: theme.spacing(1),
        width: '20vw',
        float: 'left',
        position: 'fixed',
        marginTop: theme.mixins.toolbar.minHeight + theme.spacing(2),
    }
});

function MenuBar() {
    let classes = MenuStyle();
    return (
        <Paper className={classes.root}>
            <MenuList>
                <MenuItem component={Link} to='/'>Welcome</MenuItem>
                <MenuItem component={Link} to='/intro'>Intro</MenuItem>
                <MenuItem component={Link} to='/transform'>Transform</MenuItem>
                <MenuItem component={Link} to='/applications'>Applications</MenuItem>
                <MenuItem component={Link} to='/solving'>Solving</MenuItem>
                <MenuItem component={Link} to='/inverse'>Inverse</MenuItem>
                <MenuItem component={Link} to='/graph'>Graph</MenuItem>
                <MenuItem component={Link} to='/credits'>Credits</MenuItem>
            </MenuList>
        </Paper>

    );
}

let PageStyle = makeStyles({
    root: {
        margin: theme.spacing(1),
        padding: theme.spacing(2),
        overflow: 'auto',
        marginTop: theme.mixins.toolbar.minHeight + theme.spacing(2),
        marginLeft: '20vw',
        position: 'absolute',
        right: 0,
        left: theme.spacing(2),
    },
    extra: {
        marginLeft: theme.spacing(2),
    }
});

function Page(props) {
    let classes = PageStyle();
    return (
        <div className={classes.extra}>
            <Paper className={classes.root}>
                <Typography variant='h2'>
                    {props.title}
                </Typography>
                <Box m={1.5}>
                    <Divider/>
                </Box>
                {props.children}
                <Box mt={1.5}>
                    <Button component={Link} to={props.next ? props.next : '/'} variant='outlined' color='secondary'>
                        {props.next ? 'Next Page' : 'Back to Start'} <NextIcon/>
                    </Button>
                </Box>
            </Paper>
        </div>
    );
}

function Welcome(){
    return (
        <Page title='Welcome!' next='/intro'>
            <img src={images['hello.gif']} style={{width: '40vh'}}/>
            <Typography variant='h4' paragraph>
                Welcome to the info site on exponential functions.
            </Typography>
            <Typography variant='h5'>
                Use the sidebar to head to any part of the site that you would like, or click on the button below to go to get started. Enjoy!
            </Typography>
        </Page>
    )
}

function MainPage() {
    return (
        <Page title='Intro/Basic Info' next='/transform'>
            <Typography>
                Exponential functions look somewhat similar to functions you have seen before, in that they involve
                exponents, but there is a big difference, in that the variable is now the power, rather than the base.
                Previously, you have dealt with such functions as f(x) = x^2, where the variable x was the base and the
                number 2 was the power. In the case of exponentials, however, you will be dealing with functions such as
                g(x) = 2x, where the base is the fixed number, and the power is the variable.
            </Typography>
            <img src={images['image4.png']}/>
        </Page>
    );
}


function SecondPage() {
    return (
        <Page title='Transformations and Translations' next='/applications'>
            <Typography variant="h5">
                Translations:
            </Typography>
            <Typography>
                A horizontal translation may affect the increasing / decreasing (if multiplied by a negative), the left
                hand / right hand behavior of the graph, and the y-intercept, but it won't change the location of the
                horizontal asymptote.
                A vertical translation may affect the increasing / decreasing (if multiplied by a negative), the
                y-intercept, and the location of the horizontal asymptote. It will not change whether the graph goes
                without bound or is asymptotic (although it may change where it is asymptotic) to the left or right.
                By knowing the features of the basic graphs, you can apply those translations to easily sketch the new
                function.
            </Typography>
            <img src={images['image1.png']}/>
        </Page>
    );
}

function ThirdPage() {
    return (
        <Page title='Real Life Applications' next='/solving'>
            <Typography variant='h6'>
                Compound Interest
            </Typography>
            <Typography paragraph>
                Exponential Growth:<br />
                <code>y = a(1 + r)x</code>
                <br /> <br />
                Exponential Decay: <br />
                <code>y = a(1 - r)x</code>
                <br /> <br />
                The amount in your savings account can be figured with exponential functions. Each period (I'll assume
                monthly), you get 1/12 of the annual interest rate (r) applied to your account. The new amount in the
                account is 100% of what you started with plus <code>r%/12</code> of what you started with that. That
                means that you
                now have <code>(100%+r%/12)</code> of what you started with. The next month, you will have the same
                thing, except it
                will be based on what you had at the end of the first month.
            </Typography>
            <Typography><code>A = P (1+i)^n</code></Typography>
            <Typography paragraph>
                A is the Amount in the account. P is the principal you started with. i is the periodic rate, which is
                the annual percent (written as a decimal) r, divided by the number of periods per year, m. n is the
                number of compounding periods, which is equal to the number of periods per year, m, times the time in
                years, t.
                Population
                Many times scientists will start with a certain number of bacteria or animals and watch how the
                population grows. For example, if the population doubles every 5 days, this can be represented as an
                exponential function. Most population models involve using e.
            </Typography>
            <Typography variant='h6'>
                Population
            </Typography>
            <Typography>
                Many times scientists will start with a certain number of bacteria or animals and watch how the
                population grows. For example, if the population doubles every 5 days, this can be represented as an
                exponential function. Most population models involve using e.
            </Typography>
        </Page>
    )
}

function ExtraPage(){
    return (
        <Page title='Solving Exponential Equations' next='/inverse'>
            <Typography>
                To solve exponential equations without logarithms, you need to have equations with comparable exponential expressions on either side of the "equals" sign, so you can compare the powers and solve. In other words, you have to have "(some base) to (some power) equals (the same base) to (some other power)", where you set the two powers equal to each other, and solve the resulting equation.
                <br /><br />For example: Solve <code>5^x = 5^3</code>
                <br />Since the bases ("5" in each case) are the same, then the only way the two expressions could be equal is for the powers also to be the same. That is: x = 3
                <br /><br />
                This solution demonstrates the logical basis for how this entire class of equation is solved: If the bases are the same, then the powers must also be equal; this is the only way for the two sides of the equation to be equal to each other. Since the powers must be the same, then we can set the two powers equal to each other, and solve the resulting equation.

            </Typography>
            <img src={images['image5.png']}/>
        </Page>
    )
}

function ExtraPageTwo(){
    return (
        <Page title='Inverse of Exponentials' next='/graph'>
            <Typography variant='h4'>Steps to find the inverse of Exponentials:</Typography>
            <Typography>
                <ol>
                    <li>Change f(x) to y.</li>
                    <li>Step 2 interchange x and y in the equation. | x → y | y → x</li>
                    <li>Isolate the exponential expression on one side (left or right) of the equation.</li>
                    <li>Eliminate the base “b” of the exponential expression by taking the logarithms of both sides of the equation.</li>
                    <li>Solve the exponential equation for “y” to get the inverse. Finally, replace y with the inverse notation f  -1(x) to write the final answer.</li>
                </ol>
                <img src={images['image6.png']}/>
            </Typography>
        </Page>
    );
}

function ForthPage() {
    return (
        <Page title='Graph Generator' next='/credits'>
            <Graph/>
        </Page>
    );
}

function FifthPage(){
    return (
        <Page title='Credits'>
            <Typography variant='h4' style={{display: 'inline-block'}}>Rithvik S, Sufiyan S, </Typography><Typography style={{display: 'inline-block'}} variant='caption' paragraph>&nbsp;Ashis G, and Isaiah L. </Typography>
            <Typography variant='h5' paragraph>This website took over 2000 lines of code! Check out the code <a href='https://github.com/SureDroid/MathProj' target='_blank'>here</a>!</Typography>
            <Typography variant='h5'> This site also takes in the <a href='https://material.io/design/introduction' target='_blank'>Material Design Principles</a> created by google.<br /><br />Hope you enjoyed the site!</Typography>
        </Page>
    );
}

function NotFound() {
    return (
        <Page title='Page Not Found'>
            <Typography paragraph>
                The page you were looking for could not be found.<br /> Please go to the <Link to='/'>home page</Link> or check for any spelling errors you may have put.
            </Typography>
            <Typography variant='h6'>
                Thank You!
            </Typography>
        </Page>
    );
}

function App() {
    return <React.Fragment>
        <NavBar/>
        <MenuBar/>
        <Switch>
            <Route exact path="/" component={Welcome}/>
            <Route exact path="/intro" component={MainPage}/>
            <Route exact path='/transform' component={SecondPage}/>
            <Route exact path='/applications' component={ThirdPage}/>
            <Route exact path='/solving' component={ExtraPage}/>
            <Route exact path='/inverse' component={ExtraPageTwo}/>
            <Route exact path='/graph' component={ForthPage} />
            <Route exact path='/credits' component={FifthPage} />
            <Route component={NotFound} />
        </Switch>
    </React.Fragment>
}

export default App;
